import React from "react";
import PropTypes from "prop-types";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { adminCommunitySetAllowDirectoryViewable } from "../../../reducers/admin-community-list";

const CommunityAllowDirectoryViewing = (props) => {
  const dispatch = useDispatch();

  return (
    <Box sx={{ m: 1 }}>
      <Box direction="row" alignItems="center">
        <Typography variant="body2" color="textSecondary" component="p">
          Directory
        </Typography>
        <FormControlLabel
          control={<Switch checked={props.allow_directory_viewing} />}
          label={<Typography>Directory Viewable</Typography>}
          labelPlacement="start"
          onClick={async () => {
            dispatch(adminCommunitySetAllowDirectoryViewable(props.community_id, !props.allow_directory_viewing));
          }}
        />
      </Box>
    </Box>
  );
};

CommunityAllowDirectoryViewing.propTypes = {
  allow_directory_viewing: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunityAllowDirectoryViewing);
