const ACCESS_LOGS = "/access-logs";
const ADD_MEMBER = "/add-member";
const COMMUNITY_BASE = "/community-manager/:communityId";
const COMMUNITY_CONFIGURE_SENSE_LINE = "/community/configure-sense-line";
const COMMUNITY_DETAIL = "/community-detail";
const COMMUNITY_HOLD_OPENS = "/community-hold-opens";
const COMMUNITY_HOMES = "/community-homes";
const COMMUNITY_KEYS = "/community-keys";
const COMMUNITY_SEND_MESSAGE = "/community-send-message";
const NAME = "/name";
const LOGIN = "/login";
const PICK_COMMUNITY = "/pick-community";
const ROOT = "/";
const VERIFY = "/verify";
const ADMIN_ACCOUNT = "/admin/account";
const ADMIN_ACCOUNT_LIST = "/admin/account-list";
const ADMIN_ACCESS_LOGS = "/admin/access-logs";
const ADMIN_ACCOUNT_GROUPS = "/admin/account-groups";
const ADMIN_ADD_ADMIN = "/admin/add-admin";
const ADMIN_ADD_DISTRIBUTOR = "/admin/add-distributor";
const ADMIN_ADD_INSTALLER = "/admin/add-installer";
const ADMIN_COMMUNITIES = "/admin/communities";
const ADMIN_COMMUNITIES_TO_ADD = "/admin/communities-to-add";
const ADMIN_COMMUNITY = "/admin/community";
const ADMIN_DEVICE = "/admin/device";
const ADMIN_DEVICE_MONITOR = "/admin/device-monitor";
const ADMIN_HIDDEN_DEVICE_MONITOR = "/admin/hidden-device-monitor";
const ADMIN_RECENT_DEVICE_MONITOR = "/admin/recent-device-monitor";
const ADMIN_HIDDEN_COMMUNITIES = "/admin/hidden-communities";
const ADMIN_INTEGRATED_LATCHES = "/admin/integrated-latches";
const ADMIN_MAINTENANCE_MODE = "/admin/maintenance-mode";
const ADMIN_USPS_EDIT = "/admin/usps-edit";
const ADMIN_USPS_METADATA = "/admin/usps-metadata";
const ADMIN_VENDOR = "/admin/vendor";
const ADMIN_VENDORS = "/vendors";
const ADMIN_UNIVERSAL_SEARCH = "/admin/universal-search";
const DISTRIBUTOR_ADD_INSTALLER = "/distributor/add-installer";
const DISTRIBUTOR_DEVICE_MONITOR = "/distributor/device-monitor";
const DISTRIBUTOR = "/distributor";
const INSTALLER = "/installer";
const EXTERNAL = {
  nimbioHelpPage: "https://support.nimbio.com/portal/en/kb/nimbio-labs-inc",
};

export default {
  ROOT,
  PICK_COMMUNITY,
  ACCESS_LOGS,
  LOGIN,
  VERIFY,
  NAME,
  COMMUNITY_BASE,
  COMMUNITY_CONFIGURE_SENSE_LINE,
  COMMUNITY_DETAIL,
  COMMUNITY_HOLD_OPENS,
  COMMUNITY_HOMES,
  COMMUNITY_KEYS,
  COMMUNITY_SEND_MESSAGE,
  ADD_MEMBER,
  ADMIN_ACCESS_LOGS,
  ADMIN_ACCOUNT,
  ADMIN_ACCOUNT_GROUPS,
  ADMIN_ACCOUNT_LIST,
  ADMIN_ADD_ADMIN,
  ADMIN_ADD_DISTRIBUTOR,
  ADMIN_ADD_INSTALLER,
  ADMIN_COMMUNITIES,
  ADMIN_COMMUNITIES_TO_ADD,
  ADMIN_COMMUNITY,
  ADMIN_DEVICE,
  ADMIN_DEVICE_MONITOR,
  ADMIN_HIDDEN_COMMUNITIES,
  ADMIN_HIDDEN_DEVICE_MONITOR,
  ADMIN_INTEGRATED_LATCHES,
  ADMIN_RECENT_DEVICE_MONITOR,
  ADMIN_MAINTENANCE_MODE,
  ADMIN_USPS_EDIT,
  ADMIN_USPS_METADATA,
  ADMIN_VENDOR,
  ADMIN_VENDORS,
  ADMIN_UNIVERSAL_SEARCH,
  DISTRIBUTOR_ADD_INSTALLER,
  DISTRIBUTOR_DEVICE_MONITOR,
  DISTRIBUTOR,
  INSTALLER,
  EXTERNAL,
};
