const nimbioServer = {
  account: {
    getKeysByAccountID: "com.nimbio.account.get_keys_by_account_id",
    getLoginDetails: "com.nimbio.account.get_login_details",
    setFirstName: "com.nimbio.account.set_first_name",
    setLastName: "com.nimbio.account.set_last_name",
  },
  admin: {
    subscriptions: {
      community: "com.nimbio.community.",
    },
    account: {
      setAccountData: "com.nimbio.admin.account.set_account_data",
      setAdmin: "com.nimbio.admin.account.set_admin",
      setCommunityType: "com.nimbio.admin.account.set_community_type",
      setDistributor: "com.nimbio.admin.account.set_distributor",
    },
    getAccount: "com.nimbio.admin.get_account",
    addAccountGroup: "com.nimbio.admin.add_account_group",
    addAccountToAccountGroup: "com.nimbio.admin.add_account_to_account_group",
    addAndConfirmCommunityMember: "com.nimbio.admin.add_and_confirm_community_member",
    addVendor: "com.nimbio.admin.add_vendor",
    disableVendor: "com.nimbio.admin.disable_vendor",
    enableVendor: "com.nimbio.admin.enable_vendor",
    addVendorContact: "com.nimbio.admin.add_vendor_contact",
    addVendorToAccount: "com.nimbio.admin.add_vendor_to_account",
    addVendorToBox: "com.nimbio.admin.add_vendor_to_box",
    addBoxToAccountGroup: "com.nimbio.admin.add_box_to_account_group",
    addVendorAPIKey: "com.nimbio.admin.add_vendor_api_key",
    box: {
      checkUpdate: "com.nimbio.admin.box.check_update",
      getBox: "com.nimbio.admin.box.get_box",
      getConnectionHistory: "com.nimbio.admin.box.get_connection_history_v2",
      getLatches: "com.nimbio.admin.box.get_latches",
      hide: "com.nimbio.admin.box.hide",
      requestBoxStats: "com.nimbio.admin.box.request_box_stats",
      restart: "com.nimbio.admin.box.restart",
      reveal: "com.nimbio.admin.box.reveal",
      setBoxName: "com.nimbio.admin.box.set_box_name",
    },
    community: {
      addCommunityManagerByAccountID: "com.nimbio.admin.community.add_community_manager_by_account_id",
      addCommunityManagerByPhoneNumber: "com.nimbio.admin.community.add_community_manager_by_phone",
      allowHoldOpens: "com.nimbio.admin.community.allow_hold_opens",
      allowSubKeys: "com.nimbio.admin.community.allow_subkeys",
      manager: {
        setActive: "com.nimbio.admin.community.manager.set_active",
      },
      setContactInfo: "com.nimbio.admin.community.set_contact_info",
      setLocation: "com.nimbio.admin.community.set_location",
      setNextPaymentDate: "com.nimbio.admin.community.set_next_payment_date",
      setPayment: "com.nimbio.admin.community.set_payment",
      setActive: "com.nimbio.admin.community.set_active",
      setHidden: "com.nimbio.admin.community.set_hidden",
      setName: "com.nimbio.admin.community.set_name",
      setInactive: "com.nimbio.admin.community.set_inactive",
      setKeyName: "com.nimbio.admin.community.set_key_name",
      setLatchName: "com.nimbio.admin.community.set_latch_name",
      setUnits: "com.nimbio.admin.community.set_units",
      setVisible: "com.nimbio.admin.community.set_visible",
      viewChildren: "com.nimbio.admin.community_member_view_children",
    },
    universalSearch: "com.nimbio.admin.search_universal",
    createSenseLine: "com.nimbio.admin.create_sense_line",
    createSenseLineStatus: "com.nimbio.admin.create_sense_line_status",
    deleteAccountFromAccountGroup: "com.nimbio.admin.delete_account_from_account_group",
    deleteAccountFromVendors: "com.nimbio.admin.delete_account_from_vendors",
    deleteAccountGroup: "com.nimbio.admin.delete_account_group",
    deleteBoxFromAccountGroup: "com.nimbio.admin.delete_box_from_account_group",
    deleteVendorContact: "com.nimbio.admin.delete_vendor_contact",
    deleteVendorAPIKey: "com.nimbio.admin.delete_vendor_api_key",
    findCommunity: "com.nimbio.admin.find_community",
    findUser: "com.nimbio.admin.find_user",
    getAccountGroups: "com.nimbio.admin.get_account_groups",
    getAccountGroupsByAccount: "com.nimbio.admin.get_account_groups_by_account",
    getAccountVendorOpenLogs: "com.nimbio.admin.get_account_vendor_open_logs",
    getAccountVendors: "com.nimbio.admin.get_account_vendors",
    getVendorByUUID: "com.nimbio.admin.get_vendor_by_uuid",
    getActiveCommunities: "com.nimbio.admin.get_active_communities",
    getAllBoxSenseLines: "com.nimbio.admin.get_all_box_sense_lines",
    getAllBoxSenseLineStatuses: "com.nimbio.admin.get_all_box_sense_line_statuses",
    getAllCommunityMembers: "com.nimbio.admin.get_all_community_members",
    getBoxesFromAccountGroup: "com.nimbio.admin.get_boxes_account_group",
    getBoxSenseLine: "com.nimbio.admin.get_box_sense_line",
    getButtonMessages: "com.nimbio.admin.get_button_messages",
    getCommunity: "com.nimbio.admin.get_community",
    getCommunityKeyUsageHistoryV3: "com.nimbio.admin.get_community_key_usage_history_v3",
    getHiddenCommunities: "com.nimbio.admin.get_hidden_communities",
    getInactiveCommunities: "com.nimbio.admin.get_inactive_communities",
    getLatchMessages: "com.nimbio.admin.get_latch_messages",
    getSenseLineRecordsPaginated: "com.nimbio.admin.get_sense_line_records_paginated",
    getSenseLineStatus: "com.nimbio.admin.get_sense_line_status",
    getServerReadOnlyStatus: "com.nimbio.admin.get_server_read_only_status",
    getVendorAPIKeysByVendor: "com.nimbio.admin.get_vendor_api_keys_by_vendor",
    getVendorByBox: "com.nimbio.admin.get_vendor_by_box",
    getVendorContactsByVendor: "com.nimbio.admin.get_vendor_contacts_by_vendor",
    getVendors: "com.nimbio.admin.get_vendors",
    getVendorsByAccount: "com.nimbio.admin.get_vendors_by_account",
    key: {
      addLatch: "com.nimbio.admin.key.add_latch",
      hide: "com.nimbio.admin.key.hide",
      removeLatch: "com.nimbio.admin.key.remove_latch",
      createKey: "com.nimbio.admin.create_key",
      addKeyToCommunity: "com.nimbio.community.add_key",
      removeCommunityKey: "com.nimbio.community.remove_key",
    },
    latch: {
      allowGrata: "com.nimbio.admin.latch.allow_grata",
      allowTruckbays: "com.nimbio.admin.latch.allow_truckbays",
      allowUPS: "com.nimbio.admin.latch.allow_ups",
      allowUSPS: "com.nimbio.admin.latch.allow_usps",
      allowWaymo: "com.nimbio.admin.latch.allow_waymo",
      getUSPSMetadata: "com.nimbio.admin.latch.get_usps_metadata",
      setUSPSMetadata: "com.nimbio.admin.latch.set_usps_metadata",
    },
    openLatch: "com.nimbio.admin.open_latch",
    removeVendorFromBox: "com.nimbio.admin.remove_vendor_from_box",
    searchIntegratedLatches: "com.nimbio.admin.search_integrated_latches",
    setBoxIgnoreUntilDateAndReason: "com.nimbio.admin.set_box_ignore_until_date_and_reason",
    setCommunityManagerOpenLogHistory: "com.nimbio.admin.set_community_manager_open_log_history",
    setOpenLogHistory: "com.nimbio.admin.set_open_log_history",
    setDirectoryViewable: "com.nimbio.admin.community.enable_directory_viewable",
    setCommercialKeyBilling: "com.nimbio.admin.account.set_community_commercial_key_billing",
    setCommercialKeyBillingDoM: "com.nimbio.admin.account.set_community_commercial_key_billing_day_of_month",
    setKeyMaxShareDepth: "com.nimbio.admin.set_key_max_share_depth",
    setKeyMaxShareDepthEnabled: "com.nimbio.admin.set_key_max_share_depth_enabled",
    setSenseLine: "com.nimbio.admin.set_sense_line",
    setSenseLineStatus: "com.nimbio.admin.set_sense_line_status",
    setServerReadOnlyStatus: "com.nimbio.admin.set_server_read_only_status",
    usps: {
      validateAddress: "com.nimbio.admin.usps.validate_address",
    },
    uspsMetadata: "com.nimbio.admin.usps_metadata",
  },
  auth: {
    confirmPhoneNumber: "com.nimbio.auth.confirm_phone_number",
    registerPhone: "com.nimbio.auth.register_phone",
  },
  community: {
    manager: {
      addAndConfirmCommunityMember: "com.nimbio.community.manager.add_and_confirm_member",
      addHome: "com.nimbio.community.manager.add_home",
      removeHome: "com.nimbio.community.manager.remove_home",
      box: {
        addEventHoldOpens: "com.nimbio.community.manager.box.add_event_hold_open",
        addRecurringHoldOpens: "com.nimbio.community.manager.box.add_recurring_hold_open_v2",
        addRecurringTime: "com.nimbio.community.manager.box.add_recurring_time",
        removeEventHoldOpens: "com.nimbio.community.manager.box.remove_event_hold_open",
        removeRecurringHoldOpen: "com.nimbio.community.manager.box.remove_recurring_hold_open",
        removeRecurringTime: "com.nimbio.community.manager.box.remove_recurring_time",
      },
      changeHomeAddress: "com.nimbio.community.manager.change_home_address",
      setHomeDetails: "com.nimbio.community.manager.set_home_details",
      changeKeyName: "com.nimbio.community.manager.change_key_name",
      disableCommunityKey: "com.nimbio.community.manager.disable_community_key",
      enableCommunityKey: "com.nimbio.community.manager.enable_community_key",
      confirmMember: "com.nimbio.community.manager.confirm_member",
      createSenseLine: "com.nimbio.community.manager.create_sense_line",
      createSenseLineStatus: "com.nimbio.community.manager.create_sense_line_status",
      disableHoldOpensUntil: "com.nimbio.community.manager.disable_hold_opens_until",
      enableHoldOpens: "com.nimbio.community.manager.enable_hold_opens",
      getAllBoxSenseLines: "com.nimbio.community.manager.get_all_box_sense_lines",
      getAllBoxSenseLineStatuses: "com.nimbio.community.manager.get_all_box_sense_line_statuses",
      getBoxSenseLine: "com.nimbio.community.manager.get_box_sense_line",
      getButtonMessages: "com.nimbio.community.manager.get_button_messages",
      getCommunities: "com.nimbio.community.manager.get_communities",
      getCommercialKeyUsageHistoryV3: "com.nimbio.community.manager.get_commercial_key_usage_history_v3",
      getHoldOpens: "com.nimbio.community.manager.get_hold_opens",
      getHomeDetails: "com.nimbio.community.manager.get_home_details",
      getHomes: "com.nimbio.community.manager.get_homes",
      getKeys: "com.nimbio.community.manager.get_keys",
      getLatchMessages: "com.nimbio.community.manager.get_latch_messages",
      getMember: "com.nimbio.community.manager.get_member",
      getAcceptedMembersPaginated: "com.nimbio.community.manager.get_accepted_members_paginated",
      getUnacceptedMembersPaginated: "com.nimbio.community.manager.get_unaccepted_members_paginated",
      getRemovedMembersPaginated: "com.nimbio.community.manager.get_removed_members_paginated",
      getResidentialKeyUsageHistoryV3: "com.nimbio.community.manager.get_residential_key_usage_history_v3",
      getSenseLineStatus: "com.nimbio.community.manager.get_sense_line_status",
      getSenseLineRecordsPaginated: "com.nimbio.community.manager.get_sense_line_records_paginated",
      removeMember: "com.nimbio.community.manager.remove_member",
      setMemberKeys: "com.nimbio.community.manager.set_member_keys",
      setMemberMoveOutDate: "com.nimbio.community.manager.set_member_move_out_date",
      setSenseLine: "com.nimbio.community.manager.set_sense_line",
      setSenseLineStatus: "com.nimbio.community.manager.set_sense_line_status",
      setHome: "com.nimbio.community.manager.set_home",
      setOwnerOccupied: "com.nimbio.community.manager.set_owner_occupied",
      viewChildren: "com.nimbio.community.manager.view_children",
      sendMessage: "com.nimbio.community.manager.send_message_to_community_members",
      setIsNewMemberRequestHomeEnabled: "com.nimbio.community.manager.set_is_new_member_request_home_enabled",
    },
    community: "com.nimbio.community",
    community_key: "com.nimbio.key.",
  },
  communityManager: {
    community: "com.nimbio.community_manager.community",
    latch: "com.nimbio.community_manager.latch",
  },
  distributor: {
    account: {
      setInstaller: "com.nimbio.distributor.account.set_installer",
    },
    box: {
      getPaginatedStatus: "com.nimbio.distributor.box.get_paginated_status",
      getPaginatedStatusHidden: "com.nimbio.distributor.box.get_paginated_status_hidden",
      getStatus: "com.nimbio.distributor.box.get_status",
      searchBoxStatus: "com.nimbio.distributor.box.search_box_status",
    },
  },
  vendor: {
    vendorAPIKey: "com.nimbio.vendor.vendor_api_key",
    vendorContact: "com.nimbio.vendor.vendor_contact",
    vendors: "com.nimbio.vendor.vendors",
    vendorToAccount: "com.nimbio.vendor.vendor_to_account",
    vendorToBox: "com.nimbio.vendor.vendor_to_box",
    vendorToLatch: "com.nimbio.vendor.vendor_to_latch",
  },
  internal: {
    accountGroups: "com.nimbio.internal.account_groups",
    accountToAccountGroups: "com.nimbio.internal.account_to_account_groups",
    accountList: "com.nimbio.internal.account_list",
    boxToAccountGroups: "com.nimbio.internal.box_to_account_groups",
    communityList: "com.nimbio.internal.community_list",
    device: {
      newBox: "com.nimbio.internal.device.new_box",
      statusUpdate: "com.nimbio.internal.device.status_update",
    },
    general: "com.nimbio.internal.general",
    keyList: "com.nimbio.internal.key_list",
    keyName: "com.nimbio.internal.key_name",
    latchName: "com.nimbio.internal.latch_name",
  },
  latch: "com.nimbio.latch",
  noSuchUser: "com.nimbio.no_such_user",
};

export default nimbioServer;
