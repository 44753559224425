import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { connect, useDispatch } from "react-redux";
import { adminCommunitySetCommunityType } from "../../../reducers/admin-community-list";

type CommunitySetPropertyType_Type = {
  community_id: number;
  community_type: number;
};

const PROPERTY_TYPES = {
  unknown: 0,
  residential: 1,
  commercial: 2,
  Demo: 3,
};

const PROPERTY_STRINGS = ["Unknown", "Residential", "Commercial", "Demo"];

const CommunitySetPropertyType = (props: CommunitySetPropertyType_Type) => {
  const dispatch = useDispatch();

  const handleChange = async (e: SelectChangeEvent<number>) => {
    const newCommunityType: number = Number(e.target.value);
    dispatch(adminCommunitySetCommunityType(props.community_id, newCommunityType));
  };

  return (
    <Box sx={{ minWidth: 120, maxWidth: 300, m: 1, mb: 2 }}>
      <Typography variant="body2" color="textSecondary" component="p" sx={{ mb: 1 }}>
        Property Type
      </Typography>
      <FormControl fullWidth sx={{ ml: 1 }}>
        <Select
          value={props.community_type === null ? PROPERTY_TYPES.unknown : props.community_type}
          label={""}
          onChange={(e: SelectChangeEvent<number>) => {
            handleChange(e);
          }}
          defaultValue={0}>
          <MenuItem selected value={PROPERTY_TYPES.unknown}>
            {PROPERTY_STRINGS[PROPERTY_TYPES.unknown]}
          </MenuItem>
          <MenuItem value={PROPERTY_TYPES.residential}>{PROPERTY_STRINGS[PROPERTY_TYPES.residential]}</MenuItem>
          <MenuItem value={PROPERTY_TYPES.commercial}>{PROPERTY_STRINGS[PROPERTY_TYPES.commercial]}</MenuItem>
          <MenuItem value={PROPERTY_TYPES.Demo}>{PROPERTY_STRINGS[PROPERTY_TYPES.Demo]}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default connect()(CommunitySetPropertyType);
