import React from "react";
import Box from "@mui/material/Box";
// import { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { connect, useDispatch } from "react-redux";
import { Button, FormControlLabel, Switch, TextField } from "@mui/material";
import {
  adminCommunitySetCommercialKeyBillingDoM,
  adminCommunitySetCommercialKeyBillingEnabled,
} from "../../../reducers/admin-community-list";


type CommunitySetCommercialBilling_Type = {
  community_id: number;
  commercial_key_billing_enabled: boolean;
  commercial_key_billing_day_of_month: number | null;
};

const CommunitySetCommercialBilling = (props: CommunitySetCommercialBilling_Type) => {
  const dispatch = useDispatch();
  const [dayOfMonth, setDayOfMonth] = React.useState(props.commercial_key_billing_day_of_month);

  // const handleChange = async (e: SelectChangeEvent<number>) => {
  //   const newCommunityType: number = Number(e.target.value);
  //   dispatch(adminCommunitySetCommunityType(props.community_id, newCommunityType));
  // };

  const setFromInputDayOfMonth = (value: any) => {
    value = parseInt(value);
    if (value === null || value === "") {
      return false;
    }
    if (value < 1 || value > 28) {
      return false;
    }
    if (isNaN(value)) {
      setDayOfMonth(null);
      return false;
    }
    setDayOfMonth(value);
  };

  return (
    <Box sx={{ minWidth: 120, maxWidth: 300, m: 1, mb: 2 }}>
      <Typography variant="body2" color="textSecondary" component="p" sx={{ mb: 1 }}>
        Commercial Key Billing
      </Typography>
      <FormControlLabel
        control={<Switch checked={props.commercial_key_billing_enabled} />}
        label={<Typography>Commercial Key Billing</Typography>}
        labelPlacement="start"
        onClick={async () => {
          dispatch(
            adminCommunitySetCommercialKeyBillingEnabled(props.community_id, !props.commercial_key_billing_enabled));
        }}
      />
      <TextField
        data-testid="set-billing-day-of-month"
        id="day_of_month"
        onChange={(event) => setFromInputDayOfMonth(event.target.value.trim())}
        label="Day of Month"
        value={dayOfMonth}
        variant="outlined"
        disabled={!props.commercial_key_billing_enabled}
      />
      <Button variant="contained" sx={{ marginLeft: 1 }}
              onClick={async () => {
                dispatch(adminCommunitySetCommercialKeyBillingDoM(props.community_id, dayOfMonth));
              }} disabled={!props.commercial_key_billing_enabled}>
        Save
      </Button>
    </Box>
  );
};

export default connect()(CommunitySetCommercialBilling);
